import { Outlet } from "react-router-dom";
import NavBar from "../NavBar";
import Footer from "../Footer";

export default function Layout() {
  return (
    <main className="w-full flex flex-col items-center">
      <NavBar />
      <Outlet />
      <Footer />
    </main>
  );
}

import { FaCheck } from "react-icons/fa6";

export default function Hero() {
  const items = [
    "30-Day Free Trial",
    "No Credit Card Required",
    "Cancel Anytime",
  ];

  return (
    <div className="w-full h-[80vh] lg:h-[90vh] flex flex-col justify-center items-center gap-[20px] relative p-5">
      <div className="w-full flex justify-center max-w-[1920px] relative z-[2]">
        <div className=" w-full max-w-[900px] 2xl:max-w-[1150px] text-center flex flex-col justify-center items-center gap-[20px]">
          <h1 className="text-[15px] text-gray opacity-60 uppercase ">
            MOBILE APP BUILDER
          </h1>
          <div className="w-full text-center">
            <h1 className="text-primary text-[26px] lg:text-[50px] 2xl:text-[65px] leading-[35px] lg:leading-[60px] 2xl:leading-[85px] font-bold lg:font-black   ">
              Keep your customers coming back with a branded mobile app
            </h1>
          </div>
          <div className="w-full text-center relative ">
            <p className="text-primary text-base lg:text-lg 2xl:text-2xl capitalize font-light ">
              *Even The Technophobes can do it. The Result? An App Your
              Customers (And Bank Account) Will Love. No Dev Or Design
              Experience Needed.
            </p>
            <div className="absolute -right-[7em] top-[5em] hidden lg:block">
              <div className="bg-yellow px-3 py-1 relative rounded-md shadow-[1px_1px_10px_rgba(78,99,126,0.5)]">
                <div className="w-[33px] h-[36px] overflow-hidden absolute -left-[20%] -top-[100%]">
                  <img src="/assets/Arrow.png" alt="Arrow" className="w-full" />
                </div>
                <h1 className="text-primary font-bold">Shopify Merchant</h1>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col lg:flex-row items-center justify-center gap-[30px] ">
            {items.map((item, i) => (
              <div
                className="flex items-center justify-start gap-[10px]"
                key={i}
              >
                <FaCheck className="text-yellow text-2xl" />
                <p className="text-primary text-base 2xl:text-lg capitalize font-semibold">
                  {item}
                </p>
              </div>
            ))}
          </div>

          <a
            href="https://apps.shopify.com/montu-app-builder"
            target="_blank"
            className="w-[222px] h-[57px]"
          >
            <img
              src="/assets/Shopify-App-Store-Badge-Final-Black.png"
              alt="Shopify App"
              className="w-full"
            />
          </a>
        </div>
      </div>

      {/* yellow shape */}
      <div
        className="w-[458px] h-[399px] blur-[100px] absolute top-[15%] left-auto right-[-3%] opacity-25 lg:opacity-50"
        style={{
          backgroundImage:
            "radial-gradient(circle at 50% 72.64%, #fed92a 47.4%, #fff)",
        }}
      ></div>

      {/* blue shape */}
      <div
        className="w-[381.74px] h-[412px] blur-[110px] absolute top-[30%] left-[-6%] right-auto opacity-25 lg:opacity-50"
        style={{
          backgroundImage:
            "radial-gradient(circle at 50% 72.64%, #408bed 47.4%, #fff)",
        }}
      ></div>
    </div>
  );
}

import { Link } from "react-router-dom";

export default function DemoMobileApp() {
  return (
    <div className="w-full lg:w-[90%] max-w-screen-2xl flex flex-col justify-center items-center gap-5 py-[50px] lg:py-[100px] lg:px-[87px] px-5">
      <div className="w-full p-5 lg:p-[55px] bg-primary border border-[#e2ecf9] rounded-[15px] flex flex-col lg:flex-row justify-start lg:justify-between items-center">
        <div className="w-full lg:w-1/2 flex flex-col items-center lg:items-start justify-start gap-5">
          <h1 className="text-[15px] text-light_gray uppercase ">
            DEMO MOBILE APP
          </h1>
          <div className="w-full lg:hidden flex justify-end items-center">
            <img src="/assets/Demo App.png" alt="Demo App" className="w-full" />
          </div>

          <h1 className="text-[28px] lg:text-[35px] leading-[31px] lg:leading-[42.66px] font-bold text-white ">
            See it in action: Try our demo mobile app now!
          </h1>
          <p className="capitalize text-white">
            Want to see what your mobile app could look like? Download our demo
            store mobile app and start exploring.get a feel for what your own
            branded mobile app could look like.
          </p>
          <div className="w-full flex justify-start items-center gap-[30px] mt-5">
            <Link
              to="https://play.google.com/store/apps/details?id=com.montu.peak"
              target="_blank"
            >
              <img src="/assets/GooglePlay.png" alt="GooglePlay" />
            </Link>
            <Link
              to="https://apps.apple.com/eg/app/peak-shop-health-and-fitness/id1661707127"
              target="_blank"
            >
              <img src="/assets/AppStore.png" alt="AppStore" />
            </Link>
          </div>
        </div>
        <div className="hidden lg:w-1/3 lg:flex justify-end items-center relative">
          <img src="/assets/Demo App.png" alt="Demo App" className="absolute" />
        </div>
      </div>
    </div>
  );
}

import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { RiMenu3Line, RiMenu4Line } from "react-icons/ri";
import { Collapse } from "react-collapse";

export default function NavBar() {
  const [opened, setOpened] = useState(false);
  const routes = [
    {
      name: "home",
      path: "/",
      navItem: true,
    },
    {
      name: "help center",
      path: "https://help.montuapps.com/",
      navItem: true,
      target: "_blank",
    },
    {
      name: "contact us",
      path: "/contact-us",
      navItem: true,
    },
    {
      name: "pricing",
      path: "/pricing",
      navItem: true,
    },
    {
      name: "login",
      path: "https://www.app.montuapps.com/login",
    },
    {
      name: "try now!",
      path: "https://apps.shopify.com/montu-app-builder",
    },
  ];
  const navbarRef = useRef(null);
  const location = useLocation();
  useEffect(() => {
    const handleScroll = () => {
      // if (window.innerWidth > 1023) {
      //   setOpened(true);
      // } else {
      //   setOpened(false);
      // }
      setOpened(false);
    };

    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setOpened(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="w-full max-w-screen-xl flex justify-between items-center py-3 lg:py-8 px-5 bg-[#ffffff85] backdrop-blur-[10px] lg:bg-transparent fixed lg:static top-0 left-0 right-0 z-[99999] shadow-2xl lg:shadow-none">
      <div className="w-full lg:w-[60%] flex justify-start items-center">
        <div className="w-full flex flex-col lg:flex-row items-start lg:justify-between lg:items-center">
          <div className="w-full lg:w-auto flex justify-between">
            <Link to="/" className="shrink-0">
              <img src="/Montu.png" alt="Montu" />
            </Link>
            <button
              className="text-2xl lg:hidden"
              onClick={() => setOpened(!opened)}
            >
              {opened ? <RiMenu4Line /> : <RiMenu3Line />}
            </button>
          </div>
          <ul
            ref={navbarRef}
            className="w-[70%] py-0 hidden lg:flex flex-row items-center justify-start"
          >
            {routes
              .filter((route) => route.navItem)
              .map((route) => (
                <li
                  key={route.name}
                  onClick={() =>
                    window.innerWidth > 1023 ? null : setOpened(false)
                  }
                >
                  <Link
                    to={route.path}
                    className={`text-[15px] text-primary px-5 py-3 rounded-md leading-0 font-semibold capitalize truncate ${
                      location.pathname === route.path ||
                      location.pathname === route.path + "/"
                        ? "bg-[#E2ECF9] cursor-not-allowed"
                        : "bg-transparent"
                    }`}
                    target={route.target || "_self"}
                  >
                    {route.name}
                  </Link>
                </li>
              ))}
          </ul>

          <div className="w-full lg:hidden">
            <Collapse isOpened={opened}>
              <ul
                ref={navbarRef}
                className="w-full py-8 lg:py-0 flex flex-col lg:flex-row items-center justify-start gap-10"
              >
                {routes
                  .filter((route) => route.navItem)
                  .map((route) => (
                    <li
                      key={route.name}
                      onClick={() =>
                        window.innerWidth > 1023 ? null : setOpened(false)
                      }
                    >
                      <Link
                        to={route.path}
                        className={`text-[15px] text-primary px-5 py-3 rounded-md leading-0 font-semibold capitalize truncate ${
                          location.pathname === route.path ||
                          location.pathname === route.path + "/"
                            ? "bg-[#E2ECF9] cursor-not-allowed"
                            : "bg-transparent"
                        }`}
                        target={route.target || "_self"}
                      >
                        {route.name}
                      </Link>
                    </li>
                  ))}
              </ul>
            </Collapse>
          </div>
        </div>
      </div>

      <div className=" hidden lg:flex items-center gap-5">
        <Link
          to={routes[4].path}
          className="text-[15px] leading-0 font-semibold capitalize"
        >
          {routes[4].name}
        </Link>

        <Link
          to={routes[5].path}
          className="text-[15px] leading-0 font-semibold capitalize bg-primary rounded-[10px] px-7 py-2 text-white"
          style={{ boxShadow: "0px 4px 13px rgba(0, 0, 0, 0.25)" }}
        >
          {routes[5].name}
        </Link>
      </div>
    </div>
  );
}

import Hero from "../../Components/Hero";
import Metrics from "../../Components/Metrics";
import OurFeatures from "../../Components/OurFeatures";
import DemoMobileApp from "../../Components/DemoMobileApp";
import FAQ from "../../Components/FAQ";
import RegisterNow from "../../Components/RegisterNow";

export default function Home() {
  return (
    <div className="w-full flex flex-col justify-start items-center overflow-hidden mt-28 lg:mt-0">
      <Hero />
      <Metrics />
      <OurFeatures />
      <DemoMobileApp />
      <FAQ />
      <RegisterNow />
    </div>
  );
}

export default function Hexagon({ className, width, height, icon }) {
  return (
    <div
      className={`flex justify-center items-center relative bg-primary rounded-[5px] text-white z-[2] ${className}`}
      style={{ width: width || "58px", height: height || "37px" }}
    >
      <div
        className={`absolute bg-primary rounded-[5px] -rotate-[60deg] -z-[1] ${className}`}
        style={{ width: width || "58px", height: height || "37px" }}
      ></div>
      <div
        className={`absolute bg-primary rounded-[5px] rotate-[60deg] -z-[1] ${className}`}
        style={{ width: width || "58px", height: height || "37px" }}
      ></div>
      {icon}
    </div>
  );
}

import { useState } from "react";
import {
  FaPenRuler,
  FaWandMagicSparkles,
  FaBell,
  FaRocket,
  FaChartPie,
} from "react-icons/fa6";
import Hexagon from "../Hexagon";
import { Link } from "react-router-dom";

export default function OurFeatures() {
  const [activeCard, setActiveCard] = useState(0);

  const cards = [
    {
      name: "UI Builder",
      icon: <FaPenRuler className="text-primary text-lg" />,
      title: "Design Your App Your Way",
      description:
        "Elevate Your App-Building Experience With Our UI Builder. Customize Every Component And Page To Create An App That Truly Reflects Your Brand. Choose From Over 100 Design Blocks To Help Your App Stand Out, And Modify Content With Ease. With This Powerful Tool, You Can Create A Truly Unique App That Sets Your Store Apart.",
      image: "/assets/features/UI Builder.png",
    },
    {
      name: "personalization",
      icon: <FaWandMagicSparkles className="text-primary text-lg" />,
      title: "Personalize Your App To Your Liking",
      description:
        "We Understand That Every Brand Is Unique. That's Why We've Designed Our App Builder To Give You Complete Control Over The Look And Feel Of Your App. With Our Personalization Feature, You Can Choose Your Own Color Palette, Upload Your Logo, And Even Enable Dark Mode Support. Make Your App Truly Your Own, And Give Your Customers An Experience They Won't Forget.",
      image: "/assets/features/UI Builder.png",
    },
    {
      name: "push notifications",
      icon: <FaBell className="text-primary text-lg" />,
      title: "Engage Your Customers With Push",
      description:
        "Say Goodbye To Costly Ads Platforms And Hello To Push Notifications. Our Push Notifications Feature Lets You Reach Your Customers Instantly And More Effectively. Send Instant Campaigns, Repeated Notifications, Or Timed Messages With Ease. Stay Connected With Your Customers And Keep Them Engaged With Your Brand, All Without Spending A Dime On Ads.",
      image: "/assets/features/push notifications.png",
    },
    {
      name: "publish",
      icon: <FaRocket className="text-primary text-lg" />,
      title: "Get Your App To Market Fast",
      description:
        "Ready To Go Live With Your App? Our Publish Flow Makes It Easy To Get Your App In The Hands Of Your Customers. With Just A Few Clicks, You Can Publish Your App To The Google Play Store And App Store. Say Goodbye To Complicated Publishing Processes And Hello To A Seamless App Launch.",
      image: "/assets/features/publish.png",
    },
    {
      name: "insights",
      icon: <FaChartPie className="text-primary text-lg" />,
      title: "Make Smarter Choices With Data",
      description:
        "Data Is King, And With Our Insights Feature, You'll Have Access To All The Data You Need To Make Informed Decisions. Track Trending Products, See What Customers Are Searching For, And Monitor Real-Time Sessions With Our Powerful Analytics Tools. With Heatmaps And Other Visual Aids, You Can Gain Insights Into User Behavior And Improve Your App's Performance Over Time.",
      image: "/assets/features/insights.png",
    },
  ];

  // (function changeCard() {
  //   setTimeout(() => {
  //     if (activeCard < cards.length - 1) {
  //       setActiveCard(activeCard + 1);
  //     } else {
  //       setActiveCard(0);
  //     }
  //   }, 4000);
  // })();

  return (
    <div className="w-full max-w-screen-2xl flex flex-col justify-center items-center gap-5 py-[50px] lg:py-[100px] lg:px-[87px] px-5">
      <h1 className="text-[15px] text-gray uppercase ">WHY BUILDING AN APP</h1>
      <h1 className="text-center text-[25px] lg:text-[35px] leading-[31px] lg:leading-[42.66px] font-black text-primary ">
        Experience The Convenience Of Having All Your
        <br /> Mobile Commerce Needs Met In One Platform
      </h1>
      <div className="w-full flex flex-col justify-start items-center gap-[50px]">
        <div className="w-full flex justify-center items-center gap-[10px]">
          {cards.map((card, i) => (
            <div
              key={i}
              className={`p-5 w-auto lg:w-[195px] h-auto lg:h-[125px] text-base font-normal capitalize flex flex-col justify-center items-center gap-[15px] rounded-xl cursor-pointer overflow-hidden ${
                activeCard === i
                  ? "bg-primary text-white"
                  : "bg-white text-primary"
              }`}
              onClick={() => {
                setActiveCard(i);
              }}
            >
              <div className="lg:pt-4">
                <Hexagon
                  width="35px"
                  height="24px"
                  icon={card.icon}
                  className="bg-yellow"
                />
              </div>
              <h1 className="hidden lg:block">{card.name}</h1>
              {/* {activeCard === i && (
                <div className="h-1 w-full -mb-[26px] ">
                  <div className="bg-yellow h-1 animate-featureTimer rounded-[5px]"></div>
                </div>
              )} */}
            </div>
          ))}
        </div>
        <div className="w-full lg:px-[150px] flex flex-col-reverse lg:flex-row justify-start items-center lg:justify-between lg:items-start">
          <div className="w-full lg:w-[40%] flex flex-col justify-center items-center lg:justify-strat lg:items-start gap-[15px] overflow-hidden">
            <h1 className="text-primary text-[25px] leading-[30.26px] font-medium mb-3">
              {cards[activeCard].title}
            </h1>
            <p className="text-paragraph text-base font-light leading-[30px]">
              {cards[activeCard].description}
            </p>
            <Link
              to="/"
              className="text-primary capitalize font-bold bg-yellow py-[10px] pl-[41.5px] pr-[40.5px] rounded-[5px] m-0"
            >
              Learn More
            </Link>
          </div>
          <div className="w-full lg:w-[50%] overflow-hidden">
            <img
              src={cards[activeCard].image}
              alt={cards[activeCard].name}
              className="w-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

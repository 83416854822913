import { Link } from "react-router-dom";

export default function RegisterNow() {
  return (
    <div className="w-full bg-primary flex flex-col items-center justify-start gap-5 px-5 py-10 lg:py-[112px] text-white">
      <h1 className="text-[15px] text-gray uppercase ">DEMO MOBILE APP</h1>
      <h1 className="text-center text-[25px] leading-[31px] lg:text-[50px] lg:leading-[55px] font-black max-w-[920px] capitalize">
        Register Now & Get The Limited Time Discount ⏳
      </h1>
      <p className=" text-base font-normal leading-6 max-w-2xl text-center">
        Why You Still Thinking Till Now, Register Now Or Just Book Your Demo And
        Try Montu And Enjoy Your Journey!
      </p>
      <Link
        className="bg-yellow text-primary flex items-center gap-5 mt-5 rounded-[10px] text-[30px] lg:text-[40px] font-semibold leading-[48.76px] pt-[10px] pb-[7px] px-[20px] lg:pt-[17px] lg:pb-[13px] lg:px-[40px]"
        style={{ boxShadow: "2px 0 30px 2px rgba(255,223,108,.2)" }}
        to="https://apps.shopify.com/montu-app-builder"
        target="_blank"
      >
        <span>
          $1
          <strong className="text-sm leading-[14.63px] font-normal">/Mo</strong>
        </span>
        <span className="flex flex-col capitalize">
          <strong className="text-sm lg:text-sm capitalize leading-[17.07px] font-semibold">
            limited time discount
          </strong>
          <strong className="text-base leading-[19.5px] font-light">
            for 2 months
          </strong>
        </span>
      </Link>
    </div>
  );
}

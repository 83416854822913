import { useEffect, useRef, useState } from "react";
import { Crisp } from "crisp-sdk-web";
import { FaPlus } from "react-icons/fa";
import { Collapse } from "react-collapse";

export default function ContactUs() {
  const faq = [
    {
      question: "Does the Montu app sync with my Shopify store?",
      answer:
        "Yes, Our App Seamlessly Syncs With Your Shopify Store, Ensuring Real-Time Updates Of All Collections, Orders, And Products.",
    },
    {
      question: "Does the Montu app support my Payment gateway?",
      answer: "Yes, We Support All Payment Gateways Available In Shopify.",
    },
    {
      question: "How many push notifications can I send?",
      answer:
        "As Many As You Want! We Offer Unlimited Push Notifications On All Of Our Plans",
    },
    {
      question: "Will my mobile app have Montu branding?",
      answer:
        "No, Our App Is A White-Label Mobile App Solution For Shopify, Which Means There Will Be No Montu Branding In Your App.",
    },
    {
      question:
        "How long will it take for my app to be approved on App Store and PlayStore?",
      answer:
        "Your App Could Get Approval In 2-5 Days, However, It May Take Longer. We Recommend Setting A Buffer Of 7 Days Between App Submission And App Launching.",
    },
    {
      question:
        "Does my subscription involve the Play Store and App Store accounts?",
      answer:
        "No. For Uploading The Apps The Merchant Will Require Developer Accounts For Both. Apple Developer Program Costs USD 99 Per Year And A Google Play Developer Account Costs USD 25 (One-Time).",
    },
    {
      question: "Can I change my plan later?",
      answer: "Yes, You Can Easily Change Your Plan Anytime.",
    },
    {
      question: "Can I test the app before finally publishing it?",
      answer:
        "Yes, You Can Test The App Before Publishing It By Reaching Out To Our Support Via Chat.",
    },
    {
      question: "Can I Send repeated Push Notifications automatically?",
      answer:
        "Yes, If You Are On The ENTERPRISE Plan, You Can Set Up And Customize Repeated Campaigns That Will Be Sent Automatically Until You Turn Them Off.",
    },
    {
      question: "How can I publish my app on the App Store/Play Store?",
      answer:
        "To Publish Your App On These Platforms, You Need To Fill Out The Publish App Section In The Montu App Panel And Provide Us With Admin Access To Your Developer Account.",
    },
  ];
  const [activeCard, setActiveCard] = useState(null);
  const cardRefs = useRef([]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutsideAllCards = cardRefs.current.every(
        (ref) => !ref || !ref.contains(event.target)
      );
      if (isOutsideAllCards) {
        setActiveCard(null);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleQuestion = (index) => {
    setActiveCard((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="w-full flex flex-col justify-center items-center relative overflow-hidden mt-32 lg:mt-0">
      <div className="w-full h-[90vh] max-w-screen-2xl flex flex-col lg:flex-row lg:justify-center items-center gap-[20px] p-5 ">
        <div className="w-full lg:w-[40%] flex flex-col items-start justify-start gap-[15px]">
          <h1 className="text-sm font-bold leading-[22px] text-yellow capitalize">
            Help Center
          </h1>
          <h1 className="text-primary text-2xl lg:text-[40px] lg:leading-[50px] font-extrabold ">
            We Would Love To Help You With Any Questions
          </h1>
          <p className="text-[#39435b] leading-[22px] ">
            feel free to ask us any question we collected all the questions you
            may wondered about browse to help you
          </p>
          <button
            className="bg-primary rounded-[5px] pt-[15px] pr-9 pb-[15px] pl-8 text-base laeding-[19.5px] font-semibold text-white capitalize"
            onClick={() => {
              if (!Crisp.chat.isChatOpened()) {
                Crisp.chat.open();
              }
            }}
          >
            get help
          </button>
        </div>

        <div className="w-full lg:w-[50%]">
          <img
            src="/assets/contact us.png"
            alt="contact us"
            className="lg:scale-150 scale-[2] lg:ml-20"
          />
        </div>
      </div>

      <div className="w-full bg-primary flex flex-col justify-start items-center gap-10 p-5 py-20">
        <div className="w-full flex flex-col justify-start items-center gap-5">
          <h1 className="text-[15px] text-light_gray uppercase ">FAQ</h1>
          <h1 className="text-center text-[35px] leading-[42.66px] font-black text-white ">
            Frequantly Asked Questions
          </h1>
          <p className="text-center text-sm text-[#e2ecf9] font-light">
            Looking For Answers To Common Questions About Montu? We&apos;ve
            Compiled A <br />
            List Of Common Questions And Provided Clear And Concise Answers To
            Help <br />
            You Understand And Use Our Product/Service Better.
          </p>
        </div>
        <div className="w-full flex flex-col items-start justify-start max-w-screen-xl">
          {faq.map((card, i) => (
            <div
              key={i}
              className={`w-full flex flex-col items-start justify-start border-t-2 border-[#4E637E33] p-3  cursor-pointer select-none ${
                i === faq.length - 1 && "border-b-2"
              }`}
              ref={(ref) => (cardRefs.current[i] = ref)}
              onClick={() => toggleQuestion(i)}
            >
              <div className="w-full flex justify-between items-center text-white text-lg leading-[21.6px] font-semibold duration-300">
                <h1 className="py-3 pr-2">{card.question}</h1>
                <FaPlus
                  className={`shrink-0 ${
                    activeCard === i
                      ? "rotate-45 duration-300"
                      : "rotate-0 duration-300"
                  }`}
                />
              </div>

              <Collapse isOpened={activeCard === i}>
                <p className="text-white text-base leading-[22.4px] font-light">
                  {card.answer}
                </p>
              </Collapse>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default function PrivacyPolicy() {
  return (
    <div className="w-full flex flex-col justify-start items-center gap-[50px] lg:pb-16 mt-16 lg:mt-0">
      <div className="w-full bg-primary text-center py-[50px] text-[38px] leading-[44px] text-white text-bold">
        <h1>Montu Privacy Policy</h1>
      </div>
      <div className="w-full max-w-screen-xl flex flex-col justify-start items-start gap-[20px] p-5 pb-28 lg:p-0">
        <h1 className="text-xl leading-[30px] font-semibold capitalize ">
          Montu &quot;The App” Provides A Mobile App Builder &quot;The
          Service&quot; To Merchants Who Use Shopify To Power Their Stores. This
          Privacy Policy Describes How Personal Information Is Collected, Used,
          And Shared When You Install Or Use The App In Connection With Your
          Shopify-Supported Store.
        </h1>
        <h2 className="text-lg font-normal">
          Personal Information The App Collects
        </h2>
        <p className="text-lg leading-[25px] font-light capitalize">
          When You Install The App, We Are Automatically Able To Access Certain
          Types Of Information From Your Shopify Account:
        </p>
        <p className="text-lg leading-[25px] font-light capitalize">
          Additionally, We Collect The Following Types Of Personal Information
          From You And/Or Your Customers Once You Have Installed The App:
          Information About You And Others Who May Access The App On Behalf Of
          Your Store, Such As Your Name, Address, Email Address, Phone Number,
          And Billing Information; Information About Individuals Who Visit Your
          Store, Such As Their IP Address, Web Browser Details, Time Zone, And
          Information About The Cookies Installed On The Particular Device.
        </p>
        <p className="text-lg leading-[25px] font-light capitalize">
          We Collect Personal Information Directly From The Relevant Individual,
          Through Your Shopify Account, Or Using The Following Technologies:
          “Cookies” Are Data Files That Are Placed On Your Device Or Computer
          And Often Include An Anonymous Unique Identifier. For More Information
          About Cookies, And How To Disable Cookies, Visit{" "}
          <strong>
            <a href="http://www.allaboutcookies.org" target="_blank">
              Http://Www.Allaboutcookies.Org.
            </a>
          </strong>{" "}
          “Log Files” Track Actions Occurring On The Site, And Collect Data
          Including Your IP Address, Browser Type, Internet Service Provider,
          Referring/Exit Pages, And Date/Time Stamps. “Web Beacons,” “Tags,” And
          “Pixels” Are Electronic Files Used To Record Information About How You
          Browse The Site.
        </p>
        <h2 className="text-lg font-normal">
          How Do We Use Your Personal Information?
        </h2>
        <p className="text-lg leading-[25px] font-light capitalize">
          We Use The Personal Information We Collect From You And Your Customers
          In Order To Provide The Service And To Operate The App. Additionally,
          We Use This Personal Information To: Communicate With You; Optimize Or
          Improve The App.
        </p>
        <p className="text-lg leading-[25px] font-light capitalize">
          Finally, We May Also Share Your Personal Information To Comply With
          Applicable Laws And Regulations, To Respond To A Subpoena, Search
          Warrant Or Other Lawful Request For Information We Receive, Or To
          Otherwise Protect Our Rights.
        </p>
        <p className="text-lg leading-[25px] font-light capitalize">
          Additionally, You Can Opt Out Of Some Of These Services By Visiting
          The Digital Advertising Alliance’s Opt-Out Portal At:{" "}
          <strong>
            <a href="https://optout.aboutads.info/" target="_blank">
              Https://Optout.Aboutads.Info/.
            </a>
          </strong>{" "}
        </p>

        <p className="text-lg leading-[25px] font-light capitalize">
          Your Rights If You Are A European Resident, You Have The Right To
          Access Personal Information We Hold About You And To Ask That Your
          Personal Information Be Corrected, Updated, Or Deleted. If You Would
          Like To Exercise This Right, Please Contact Us Through The Contact
          Information Below.
        </p>
        <p className="text-lg leading-[25px] font-light capitalize">
          Additionally, If You Are A European Resident We Note That We Are
          Processing Your Information In Order To Fulfill Contracts We Might
          Have With You (For Example If You Make An Order Through The Site), Or
          Otherwise To Pursue Our Legitimate Business Interests Listed Above.
          Additionally, Please Note That Your Information Will Be Transferred
          Outside Of Europe, Including To Canada And The United States.
        </p>
        <p className="text-lg leading-[25px] font-light capitalize">
          Data Retention When You Place An Order Through The Site, We Will
          Maintain Your Order Information For Our Records Unless And Until You
          Ask Us To Delete This Information.
        </p>
        <p className="text-lg leading-[25px] font-light capitalize">
          Changes We May Update This Privacy Policy From Time To Time In Order
          To Reflect, For Example, Changes To Our Practices Or For Other
          Operational, Legal Or Regulatory Reasons.
        </p>

        <p className="text-lg leading-[25px] font-light capitalize">
          Contact Us For More Information About Our Privacy Practices, If You
          Have Questions, Or If You Would Like To Make A Complaint, Please
          Contact Us By E-Mail At:{" "}
          <strong>
            <a
              href="mailto:contact@montuapps.com"
              rel="noreferrer"
              target="_blank"
            >
              contact@montuapps.com
            </a>
          </strong>{" "}
        </p>
      </div>
    </div>
  );
}

import { useEffect, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa6";

export default function FAQ() {
  const [activeCard, setActiveCard] = useState(null);

  const cards = [
    {
      question: "Does the Montu app sync with my Shopify store?",
      answer:
        "Yes, our app seamlessly syncs with your Shopify store, ensuring real-time updates of all collections, orders, and products.",
    },
    {
      question: "Does the Montu app support my Payment gateway?",
      answer: "Yes, we support all payment gateways available in Shopify.",
    },
    {
      question: "How many push notifications can I send?",
      answer:
        "as many as you want! we offer unlimited push notifications on all of our plans",
    },
    {
      question: "Will my mobile app have Montu branding?",
      answer:
        "No, our app is a white-label mobile app solution for Shopify, which means there will be no Montu branding in your app.",
    },
    {
      question:
        "How long will it take for my app to be approved on App Store and PlayStore?",
      answer:
        "Your app could get approval in 2-5 days, however, it may take longer. We recommend setting a buffer of 7 days between app submission and app launching.",
    },
    {
      question:
        "Does my subscription involve the Play Store and App Store accounts?",
      answer:
        "No. For uploading the apps the Merchant will require developer accounts for both. Apple Developer Program costs USD 99 per year and a Google Play Developer Account costs USD 25 (one-time).",
    },
  ];

  const cardRefs = useRef([]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside any of the card elements
      const isOutsideAllCards = cardRefs.current.every(
        (ref) => !ref || !ref.contains(event.target)
      );
      if (isOutsideAllCards) {
        setActiveCard(null);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div className="w-full max-w-screen-2xl flex flex-col justify-center items-center gap-5 py-[50px] lg:py-[100px] lg:px-[237px] px-5">
      <h1 className="text-[15px] text-gray uppercase ">FQA</h1>
      <h1 className="text-center text-[27px] leading-[31px] lg:text-[45px] lg:leading-[80px] font-semibold text-primary capitalize">
        Frequantly Asked Questions
      </h1>
      <p className="text-gray text-center lg:text-start text-sm lg:-mt-5">
        No Code Or Design Experience Needed With Montu, We Will Help You Sync
        Your Store Easily And Create A Greate Mobile App For Your Shopify Store.
      </p>
      <div className="w-full flex flex-col lg:flex-row lg:flex-wrap items-center lg:items-start justify-start lg:justify-between gap-[15px]">
        {cards.map((card, i) => (
          <div
            key={i}
            className={`h-[100px] w-full lg:w-[49%]  cursor-pointer relative `}
            ref={(ref) => (cardRefs.current[i] = ref)}
            onClick={() => {
              if (activeCard === i) {
                setActiveCard(null);
              } else {
                setActiveCard(i);
              }
            }}
          >
            <div
              className={`text-primary bg-white border border-[#e2ecf9] rounded-[10px] h-full px-[25px] flex items-center justify-between select-none absolute w-full ${
                activeCard === i
                  ? "animate-frontFaceIn"
                  : "animate-frontFaceOut"
              }`}
              style={{
                backfaceVisibility: "hidden",
              }}
            >
              <h1 className="text-lg leading-[30px] font-normal">
                {card.question}
              </h1>
              <div className="flex justify-end w-[10%] flex-shrink-0">
                <FaPlus className="text-2xl" />
              </div>
            </div>

            <div
              className={`text-primary bg-white border border-[#e2ecf9] rounded-[10px] h-full px-[25px] flex items-center justify-between select-none absolute w-full origin-backFace ${
                activeCard === i ? "animate-backFaceIn" : "animate-backFaceOut"
              }`}
              style={{
                backfaceVisibility: "hidden",
                transform: "rotateX(180deg) rotateY(0deg) rotateZ(0deg)",
              }}
            >
              <h1 className="text-sm leading-[20px] font-normal">
                {card.answer}
              </h1>
              <div className="flex justify-end w-[10%] flex-shrink-0">
                <FaPlus className="text-2xl rotate-45" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

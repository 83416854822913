import { Switch } from "@headlessui/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { FaHashnode } from "react-icons/fa6";

export default function Pricing() {
  const [enabled, setEnabled] = useState(false);
  const plans = [
    {
      name: "CORE",
      mostPopular: false,
      monthlyPrice: 69,
      annualPrice: 55,
      savedPrice: 165.6,
      title: "Perfect For Those Just Getting Started",
      features: [
        "IOS & Android App",
        "Limited Design Blocks",
        "Unlimited Push Notifications",
        "Live Chat Support",
        "Unlimited App Updates",
      ],
    },
    {
      name: "PRO",
      mostPopular: true,
      monthlyPrice: 1,
      annualPrice: 79,
      savedPrice: 237.6,
      title: "Ideal For Small-Sized Businesses & Brands",
      features: [
        "Unlimited Design Blocks",
        "Unlimited Navigation Control",
        "Notification Campaign Insights",
        "Customer Behavior Insights",
        "App Dark Mode Support",
        "Product Wishlist",
      ],
    },
    {
      name: "ENTERPRISE",
      mostPopular: false,
      monthlyPrice: 299,
      annualPrice: 239,
      savedPrice: 717.6,
      title: "Best Suited For Larger Brands",
      features: [
        "Automated Push Notifications",
        "Timed Push Notifications",
        "Push Notifications Deep Linking",
        "Full Notifications Insights",
        "Full Customer Behavior Insights",
        "Custom Feature Development",
        "Direct Communication On Slack",
        "Dedicated UI / UX Designer",
      ],
    },
  ];
  return (
    <div className="w-full px-5 lg:px-0 flex flex-col justify-start items-center gap-[60px] lg:pb-16 mt-32 lg:mt-0">
      <div className="w-full flex flex-col justify-start items-center gap-[30px]">
        <div className="bg-[#FED92A40] py-[6.5px] pr-[23px] pl-[20px] rounded-[5px] ">
          <h1 className="text-primary font-bold leading-[22px]">
            30 Days Free Trial
          </h1>
        </div>

        <h1 className="text-primary text-2xl lg:text-[35px] font-bold lg:leading-[45px] capitalize text-center w-full lg:max-w-[40%]">
          We&rsquo;ve Got A Transparent Pricing Plans That&rsquo;s Perfect For
          You
        </h1>

        <div className="flex gap-5 justify-center items-center duration-200 ease-in-out">
          <h1
            className={`text-[24px] font-semibold leading-[26.51px] ${
              enabled ? "opacity-[0.4]" : "opacity-100"
            }`}
          >
            Monthly
          </h1>

          <Switch
            checked={enabled}
            onChange={setEnabled}
            className={`${
              enabled ? "bg-yellow" : "bg-primary"
            } relative flex items-center h-[30px] w-[70px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none `}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={`${
                enabled ? "translate-x-11" : "translate-x-1"
              } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>

          <h1
            className={`text-[24px] font-semibold leading-[26.51px] ${
              enabled ? "opacity-100" : "opacity-[0.4]"
            }`}
          >
            Annualy
          </h1>
        </div>
        <p className="leading-[17.07px] font-medium text-sm -mt-4">
          <span className="relative">
            Save Up To $420+
            <span className="w-full h-[6px] absolute z-[-1] left-0 bottom-[1.5px] bg-yellow rounded-[5px]"></span>
          </span>{" "}
          With Annual Payment
        </p>
      </div>
      <div className="w-full flex flex-col lg:flex-row justify-center items-center gap-[30px]">
        {plans.map((plan, index) => (
          <div
            className={`w-full lg:w-[350px] flex flex-col gap-[15px] border-2 border-[#e2ecf9] rounded-[10px] overflow-hidden ${
              plan.mostPopular
                ? "bg-primary text-white lg:h-[85vh]"
                : "bg-transparent text-primary lg:h-[75vh]"
            } `}
            key={index}
          >
            {plan.mostPopular && (
              <div className="bg-yellow text-primary text-center text-[10px] font-semibold uppercase py-[5px] px-[10px]">
                2 Months For 1$/Mo
              </div>
            )}

            {/* plan pricing */}
            <div className="flex flex-col items-start justify-start gap-2 pt-[40px] pr-[39.79px] pl-[30px]">
              <h1 className="text-sm font-bold leading-[17.07px] uppercase">
                {plan.name}
              </h1>
              <h1 className="capitalize mt-3 text-[50px] font-bold leading-[60.95px]">
                ${!enabled ? plan.monthlyPrice : plan.annualPrice}
                <span className="text-xs font-semibold leading-[14.63px]">
                  /mo
                </span>{" "}
                {plan.mostPopular && (
                  <span className="text-[24.4px] leading-[29.74px] pt-5 text-[#ffdf6c] opacity-[0.66] line-through	">
                    $99
                  </span>
                )}
                {!plan.mostPopular && enabled && (
                  <span className="text-[24.4px] leading-[29.74px] pt-5 text-primary opacity-[0.4] line-through	">
                    ${plan.monthlyPrice}
                  </span>
                )}
              </h1>
              <p
                className={`text-[12px] leading-[14.63px] font-medium ${
                  plan.mostPopular ? "text-[#f7f9fd]" : "text-[#39435b]"
                }`}
              >
                Billed {enabled ? "Annually" : "Monthly"}
                {enabled && <strong> (Save ${plan.savedPrice})</strong>}
              </p>

              <p
                className={`text-[12px] leading-[14.63px] font-light ${
                  plan.mostPopular ? "text-white" : "text-primary"
                }`}
              >
                {plan.title}
              </p>
              <Link
                to="/"
                className={`w-full text-center capitalize rounded-[8px] mt-[8.12px] py-3 text-sm leading-[17.07px] font-semibold ${
                  plan.mostPopular
                    ? "bg-yellow text-primary"
                    : "bg-primary text-white"
                }`}
              >
                try for free
              </Link>
            </div>

            {/* plan details */}
            <div className="w-full text-start pr-[39.79px] pb-[64px] pl-[30px] flex flex-col justify-start items-start gap-2">
              <h1
                className={`text-xs font-medium leading-[17.07px] ${
                  plan.mostPopular ? "text-white" : "text-[#39435b]"
                }`}
              >
                Features
              </h1>
              <p className="text-xs font-light leading-[14.63px] -mt-2">
                The CORE Includes:
              </p>
              <ul className="text-xs font-light leading-[14.63px] flex flex-col justify-start items-start gap-[18px]">
                {plan.features.map((feature, index) => (
                  <li key={index} className="flex items-center gap-[5.5px]">
                    <FaHashnode />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

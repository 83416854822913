import { FaSackDollar, FaUserLarge } from "react-icons/fa6";
import { FaBell, FaBoxOpen } from "react-icons/fa";
import Hexagon from "./../Hexagon/index";

export default function Metrics() {
  const cards = [
    {
      icon: <FaSackDollar className="text-[30.79px] leading-[30.09px]" />,
      title: "2.3x",
      description: "Conversion Rate",
    },
    {
      icon: <FaBell className="text-[30.79px] leading-[30.09px]" />,
      title: "40%",
      description: "Push Notification Open Rate Vs 20% Email",
    },
    {
      icon: <FaUserLarge className="text-[30.79px] leading-[30.09px]" />,
      title: "5x",
      description: "Customer Retention Than Text Sms Or Email",
    },
    {
      icon: <FaBoxOpen className="text-[30.79px] leading-[30.09px]" />,
      title: "40%",
      description: "Higher AOV (Average Order Value)",
    },
  ];
  return (
    <div className="w-full bg-primary flex flex-col justify-center items-center gap-5 py-[50px] lg:py-[100px] lg:px-[87px] px-5">
      <h1 className="text-[15px] text-light_gray uppercase ">
        WHY BUILDING AN APP
      </h1>
      <h1 className="px-5 text-center text-[25px] lg:text-[35px] leading-[31px] lg:leading-[42.66px] font-black text-white ">
        The Metrics Don&#39;t Lie: 79% Of Shopify <br />
        Traffic Comes From Mobile
      </h1>
      <div className="w-full flex flex-col lg:flex-row justify-center items-start gap-[30px] px-[10%]">
        {cards.map((card, i) => (
          <div
            className="w-full lg:w-1/4 flex flex-col justify-center items-center gap-[20px] pt-[42px] pb-[25px] px-[23px] bg-white rounded-[20px] "
            key={i}
          >
            <Hexagon
              width={"58px"}
              height={"37px"}
              icon={card.icon}
              className="text-yellow"
            />
            <h1 className="text-primary text-[40px] leading-[48.76px] font-bold ">
              {card.title}
            </h1>
            <div className="h-[40px]">
              <p className="text-primary text-base leading-[17.07px] text-center opacity-80 ">
                {card.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default function Footer() {
  return (
    <div className="w-full bg-white flex flex-col items-center">
      <div className="w-full lg:mt-[70px] max-w-screen-xl flex flex-col justify-start items-center gap-10 p-5">
        <a href="/" className="shrink-0">
          <img src="/Montu.png" alt="Montu" />
        </a>
        <p className="w-[90%] lg:w-1/2 text-center text-base leading-[25px] text-[#4e637e]">
          Montu Is A No-Code Mobile App Builder For Shopify Merchants. We Help
          You Build A Native Mobile App Instantly, Without The Need For
          Expensive Dev Teams Or Prior Knowledge Of App-Building.
        </p>
        <a
          href="https://apps.shopify.com/montu-app-builder"
          target="_blank"
          className="w-[222px] h-[57px]"
        >
          <img
            src="/assets/Shopify-App-Store-Badge-Final-Black.png"
            alt="Shopify App"
            className="w-full"
          />
        </a>
        <div className="w-full flex flex-wrap justify-between items-center lg:mt-16 gap-y-5">
          <a
            href="https://help.montuapps.com/"
            target="_blank"
            className="text-[#4e637e] capitalize text-base w-1/2 lg:w-auto text-center"
          >
            help center
          </a>
          <a
            href="/contact-us"
            className="text-[#4e637e] capitalize text-base w-1/2 lg:w-auto text-center"
          >
            contact us
          </a>
          <a
            href="/pricing"
            className="text-[#4e637e] capitalize text-base w-1/2 lg:w-auto text-center"
          >
            pricing
          </a>
          <a
            className="text-[#4e637e] capitalize text-base w-1/2 lg:w-auto text-center"
            href="/privacy-policy"
          >
            Privacy Policy
          </a>
        </div>
      </div>
      <div className="w-full max-w-screen-xl border-t-2 border-[#e5e5ea] text-center pt-5 pb-6 text-[#4e637e] capitalize text-sm lg:text-base lg:leading-[26px] font-light ">
        Copyright © 2024 Montu, LLC. All Rights Reserved.
      </div>
    </div>
  );
}

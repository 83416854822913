import { Routes, Route } from "react-router-dom";
import { Crisp } from "crisp-sdk-web";

import Layout from "./Components/Layout";
import Home from "./Pages/Home";
import Pricing from "./Pages/Pricing";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import ContactUs from "./Pages/ContactUs";

function App() {
  Crisp.configure("17dfc805-0211-4732-a9b8-a8387f387ca7");

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Route>
    </Routes>
  );
}

export default App;
